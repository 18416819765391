export const languages = [
  /*{ 
    code: "en",
    nativeName: "English",
  },*/
  { 
    code: "fr",
    nativeName: "Français",
  },
]
