import React, { Component } from "react";
import AppRoutes from "./routes/routes";
import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getPageUrl } from "./locales/i18n";

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>
            Dev Together {/*transforme votre projet en un produit fonctionnel et
            performant !*/}
          </title>
          <link href={'https://www.dev-together.com' + getPageUrl()} />
        </Helmet>
        <Wrapper>
          <AppRoutes />
        </Wrapper>
      </HelmetProvider>
    );
  }
}

export default App;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;
