import { instanceAxios } from "../utils/axios-api";

// GET ALL
export const onGetAllPublishedProjectsApi = () => {
  return instanceAxios
    .get(`/projects/get-all-published-projects`)
    .then((response) => (response.data ? response.data.data : null))
}

// GET ONLY ONE PROJECT
export const onGetProjectApi = (id : number) => {
  return instanceAxios
    .get(`/projects/unique/${id}`)
    .then((response) => (response.data) ? response.data.data : null)
}
