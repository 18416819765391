import { instanceAxios } from "../utils/axios-api";

export const onGetAllItemsApi = () => {
  return instanceAxios
    .get(`/items/get-all-items`)
    .then((response) => (response.data ? response.data.data : null))
}

export const onGetItemApi = (id : number) => {
  return instanceAxios
    .get(`/items/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null))
}
