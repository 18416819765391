export const COLOR_BACKGROUND_BODY = "#F9F9F9";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
export const COLOR_TEXT = "#262626";
export const COLOR_GRADIENT_1 = '#B446FF';
export const COLOR_GRADIENT_2 = '#6F68FE';
export const COLOR_SPECIAL = '#6F67FE';
export const COLOR_GREY = '#999999';
export const COLOR_GREY_DARK = '#848484';
export const COLOR_GREY_LIGHT = '#C6C6C6';
export const COLOR_ORANGE_HOVER = '#FF6A00'
export const COLOR_ORANGE_LIGHT = '#FF8A03';
export const COLOR_ORANGE_DARK = '#D66703';
export const COLOR_ORANGE_TRANSPARENCY = '#FF6A0020';
export const COLOR_GREEN = '#3DCD5C';
export const COLOR_RED = 'red'

// NEW COLORS
export const COLOR_PINK = '#F75CA0';
export const COLOR_GREEN_CYAN = '#8CDCCC';
export const COLOR_GREY_2 = '#DCDCDE';