import { instanceAxios } from "../utils/axios-api";

// GET ALL CLIENTS
export const onGetAllClientsApi = () => {
  return instanceAxios
    .get(`/clients/get-all/`)
    .then((response) => (response.data ? response.data.data : null))
}

// GET CLIENT
export const onGetClientBySlugApi = (slug : string) => {
  return instanceAxios
    .get(`/clients/get-by-slug/${slug}`)
    .then((response) => (response.data ? response.data.data : null))
}