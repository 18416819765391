import { instanceAxios } from "../utils/axios-api";

export const onGetAllArticlesApi = async () => {
  const response = await instanceAxios
    .get(`/articles/get-all-articles`);
  return (response.data ? response.data.data : null);
}

export const onGetArticleApi = async (id: string) => {
  return instanceAxios
    .get(`/articles/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null))
}

export const onGetNumberArticlesCardsApi = async (nbCards: number, slug?: string) => {
  const response = await instanceAxios
    .get(`/articles/get-3-articles/${nbCards}/${slug}`);
  return (response.data ? response.data.data : null);
}