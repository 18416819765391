import React, { useState, useCallback, createContext } from 'react'
import { onGetAllArticlesApi, onGetArticleApi, onGetNumberArticlesCardsApi } from '../../api/article.api';
import { onGetBlogArticlesFromPageNumberApi } from '../../api/blog.api';
import { IArticlesContext } from '../../interfaces/articles';

const ArticlesContext = createContext(null)

export function ArticlesProvider(props: any){

  const [isLoading, _setIsLoading] = useState(false);

  //get all articles
  const onGetAllArticles = useCallback(() => {
    _setIsLoading(true)
    return  onGetAllArticlesApi()
              .then((response) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error) => {
                _setIsLoading(false)
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })

  }, [])

   //get custom article with id
   const onGetArticle = useCallback((slug : string) => {
    _setIsLoading(true);
    return  onGetArticleApi(slug)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                _setIsLoading(false)
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  //get 3 random articles with or without current article id
  const onGetNumberArticlesCards = useCallback((nbCards:number, slug?: string) => {
    _setIsLoading(true);
    return onGetNumberArticlesCardsApi(nbCards, slug)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                _setIsLoading(false)
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  //get articles from blog page with id
  const onGetBlogArticlesFromPageNumber = useCallback((id:number) => {
    _setIsLoading(true);
    if(isNaN(id)) id = 1
    return onGetBlogArticlesFromPageNumberApi(id)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                _setIsLoading(false)
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  return (
    <ArticlesContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onGetAllArticles,
        onGetArticle,
        onGetNumberArticlesCards,
        onGetBlogArticlesFromPageNumber
      }}
    />
  );
}


export const useArticles = () : IArticlesContext => {
  const context = React.useContext(ArticlesContext);
  if (!context)
    throw new Error(
      "useArticles must be used in ArticleProvider"
    );

  return context;
};
