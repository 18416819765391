import React, { ReactNode, useEffect } from "react";
import styled, { css } from "styled-components";
import Header from "./HeaderTmp";
import HeaderHome from "./HeaderTmpV2";
import Footer from "./Footer";
import * as PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import PATH from "../constants/cts_routes";
import { scrollTo } from "../utils/utils";
import { gsap } from "gsap";
import { SMALL_SCREEN } from "../constants/cts_windows";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { AiFillBell } from "react-icons/ai";

/**
 * This component is used to encompass the Header, Footer and given children together
 */
const WrapperConnected = ({
  children,
  headerFixed = false,
}: {
  children: ReactNode;
  headerFixed?: boolean;
}) => {
  const location = useLocation();
  const urlFrom = location.pathname.split("/");
  const isStarterPack = urlFrom[urlFrom.length - 1] === PATH.starterPack;
  const isHome = urlFrom.length === 2 || urlFrom[urlFrom.length - 1] === "";
  const { t } = useTranslation();

  useEffect(() => {
    if ($(window).width() > SMALL_SCREEN) {
      gsap.set(".home-menu", { opacity: 1 });
      gsap.to(".home-menu", {
        opacity: 0,
        ease: "power1.out",
        scrollTrigger: {
          start: "top top",
          end: "top+=20px top",
          scrub: 1,
        },
      });
    }
  });

  return (
    <Wrapper>
      <HelpPanel>
        <p id="info-bubble">
          Pour toutes demandes d'informations, vous pouvez nous contacter à{" "}
          <a href="mailto:contact@dev-together.com">contact@dev-together.com</a>
        </p>
      </HelpPanel>
      {/**!isStarterPack && <Header headerFixed={headerFixed} />*/}
      {!isHome && <Header headerFixed={headerFixed} />}
      {isHome && $(window).width() > SMALL_SCREEN && (
        <div className="home-menu">
          <span className="dev-t">DevTogether</span>
          <p onClick={() => scrollTo("#goal")}>Notre mission</p>
          <p onClick={() => scrollTo("#services")}>Expertise</p>
          <p onClick={() => scrollTo("#partenairs")}>Références</p>
          <p></p>
        </div>
      )}
      {isHome && $(window).width() <= SMALL_SCREEN && (
        <HeaderHome headerFixed={headerFixed}/>
      )}
      <Container $marginTop={!isStarterPack && !headerFixed}>
        {children}
      </Container>

      {!isHome && <Footer />}
    </Wrapper>
  );
};

export default WrapperConnected;

WrapperConnected.propTypes = {
  /** Children to render*/
  children: PropTypes.any.isRequired,
};

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const HelpPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 4px 8px;
  z-index: 200;

  p {
    text-align: center;
    font-size: 14px;
  }

  a {
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  position: relative;

  .home-menu {
    @media screen and (max-width: ${SMALL_SCREEN}px) {
      p {
        display: none;
      }
    }
    display: flex;
    width: 80%;
    position: fixed;
    background: white;
    text-align: center;
    top: 42px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 6px 40px;
    border-radius: 60px;
    align-items: center !important;
    justify-content: space-between !important;
    z-index: 1;

    p {
      position: relative;
      font-family: CabinetGroteskVariable;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      pointer-events: auto;
      cursor: pointer;
    }

    .dev-t {
      font-family: CabinetGroteskVariable;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
    }
  }

  .appointement {
    .icon-bell  {
      position: absolute;
      color: red;
      top: -2px;
      right: -31px;
      width: 20x;
      height: 20px;
      color: black;
      background: #ce2727;
      
      border-radius: 15px;
      padding: 3px;
      animation: swing 2.5s ease-in-out infinite;
    }
  }

  @keyframe swing {
    0%{transform: rotate(20deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(20deg);}
  }
`;

const Container = styled.div<{ $marginTop?: boolean }>`
  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: 50px;
    `}
`;
