import { instanceAxios } from "../utils/axios-api";

// Send Candidacy Message
export const onSendCandidacyMessageApi = (
  {firstName, lastName, email, phoneNumber, presentation, cv}:
  {firstName: string, lastName: string, email: string, phoneNumber: string, presentation: string, cv: File}
) => {

  const bodyFormData = new FormData();

  bodyFormData.append("firstName", firstName);
  bodyFormData.append("lastName", lastName);
  bodyFormData.append("email", email);
  bodyFormData.append("phoneNumber", phoneNumber);
  bodyFormData.append("presentation", presentation);
  bodyFormData.append("cv", cv);

  return instanceAxios
    .post("/candidacies/create-candidacy", bodyFormData)
    .then((response) => (response.data) ? response.data : null);
}