import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { footerElements, footerArticles, footerJobs } from "../constants/cts_footer";
import { getPageUrl } from "../locales/i18n";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logos/Dev-Together-logo-footer.svg";
//import logo from "../assets/images/Dev-Together-logo-defonce.png";
import PATH from "../constants/cts_routes";
import typography from "../constants/cts_typo";
import { COLOR_BLACK, COLOR_ORANGE_HOVER, COLOR_ORANGE_TRANSPARENCY, COLOR_WHITE } from "../constants/cts_colors";
import { IMenuFooterElements } from "../interfaces/menu";

/**
 * This component is used to define and design the footer elements such as contacts, adresse or many other informations/internal links
 * It loops through a footerElements array object defined as  :
 * ````
 *  [
      { 
        name: string, 
        to: string
      },
      ...
  ] 
 * ````
 * to render elements
 */

  const Footer = () => {
  const { t } = useTranslation();

  let jobsToPrint : IMenuFooterElements[] = footerJobs
  let articlesToPrint : IMenuFooterElements[] = footerJobs


  if (footerJobs.length >= 6)
    jobsToPrint = footerJobs.slice(0,5)
  if (footerArticles.length >= 6)
    articlesToPrint = footerArticles.slice(0,5)

  return (
    <Wrapper>
      <Top>
        <LogoContainer>
          <Link to={getPageUrl(PATH.home)}>
            <Logo srcSet={logo} alt="logo"/>
          </Link>
        </LogoContainer>
        <Elements>
        { 
          footerElements.map((element) => {
            return (
              <StyledNavLink
                to={getPageUrl(element.to)}
                key={"footer-element-" + footerElements.indexOf(element)}
                className='item'
              >
                {t(`elements.${element.name}`, {ns: "footer"})}
              </StyledNavLink>
            )
          })
        }
        </Elements>
        <Articles>
        {
          articlesToPrint.map((element) => {
            return (
              <StyledNavLink
                to={getPageUrl("article/"+element.to)}
                key={"footer-article-" + footerArticles.indexOf(element)}
                className='item'
              >
                {t(`elements.${element.name}`, {ns: "footer"})}
              </StyledNavLink>
            )
          })
        }
        {
          footerArticles.length >= 6 && 
            <StyledNavLink
              to={getPageUrl(PATH.blog)}
              key={"footer-article-see-more"}
              className='item'
            >
              {t(`elements.seeMore`, {ns: "footer"})}
            </StyledNavLink>
        }
        </Articles>
        <Jobs>
        {
          jobsToPrint.map((element) => {
            return (
              <StyledNavLink
                to={getPageUrl(element.to)}
                key={"footer-job-" + footerJobs.indexOf(element)}
                className='item'
              >
                {t(`elements.${element.name}`, {ns: "footer"})}
              </StyledNavLink>
            )
          })
        }
        {
          footerJobs.length >= 6 && 
            <StyledNavLink
              to={getPageUrl(PATH.recruitment)}
              key={"footer-job-see-more"}
              className='item'
            >
              {t(`elements.seeMore`, {ns: "footer"})}
            </StyledNavLink>
        }
        </Jobs>
      </Top>
      <Address>
        <p>Dev Together, 2 Rue Vincent Van Gogh, 93360 Neuilly Plaisance, contact@dev-together.com</p>
      </Address>
    </Wrapper>
  )
}

export default Footer;


/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const Wrapper = styled.div`               // Ici, définir le style globale du fotoer (hauteur, largeur, couleur de fond, font ....)
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 32px 30px;
  position: relative;
  background-color: ${COLOR_ORANGE_HOVER};
  color: ${COLOR_WHITE};
`

const Top = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  width: 100%;
  gap: 3rem;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    gap: 0;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: center;
  }

`

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  color: ${COLOR_WHITE};

`

const LogoContainer = styled.div`
  display: flex;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 1100px) {
    margin: 0;
    height: 100%;
    flex-basis: 50%;
  }
`

const Logo = styled.img`
  width: 130px;
  height: 80px;

  // ========= MEDIA QUERIES - Logo ============
  // @media (max-width: 1100px) {
  //   width: 60px;
  //   height: 30px;
  // }
`


const Elements = styled.div`
  display: flex;
  flex-direction: column;

  width: 25%;
  font-size: 16px;

  .item {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  @media (max-width: 1100px) {
    flex-basis: 50%;
    margin-bottom: 5%;
  }
  
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 10%;
  }
`

const Articles = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  .item {
    margin-bottom: 25px;
  }

  @media (max-width: 1100px) {
    flex-basis: 50%;
    padding-right: 50px;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 10%;
    
    .item{
      margin-bottom: 10px;
    }
  }
`

const Jobs = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  .item {
    margin-bottom: 25px;
  }

  @media (max-width: 1100px) {
    flex-basis: 50%;
  }

  @media (max-width: 650px) {
    width: 100%;
    .item{
      margin-bottom: 10px;
    }
  }
`

const Address = styled.div`
  box-sizing: border-box;
  margin-top: 30px;

`
