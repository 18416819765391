import React from "react";
import { AppProvider } from "./appContext";
import { AuthentificationProvider } from "./authentificationContext";
import { UserProvider } from "./userContext";
import { ItemsProvider } from "./itemContext";
import { PaymentProvider } from "./paymentContext";
import { ClientProvider } from "./clientContext";
import { JobsProvider } from "./jobContext";
import { ArticlesProvider } from "./articlesContext";
import { CandidacyProvider } from "./candidaciesContext";
import { ImagesProvider } from "./imagesContext";
import { ProjectsProvider } from "./projectContext";

const Providers = (props: any) => {
  return (
      <ClientProvider>
        <PaymentProvider>
          <UserProvider>
            <AppProvider>
              <ItemsProvider>
                <ArticlesProvider>
                  <JobsProvider>
                    <CandidacyProvider>
                      <ImagesProvider>
                        <ProjectsProvider>
                          <AuthentificationProvider {...props} />
                        </ProjectsProvider>
                      </ImagesProvider>
                    </CandidacyProvider>
                  </JobsProvider>
                </ArticlesProvider>
              </ItemsProvider>
            </AppProvider>
          </UserProvider>
        </PaymentProvider>
      </ClientProvider>
  )
}

export default Providers;