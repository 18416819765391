export const SITE_CAPITAL = "500"
export const SITE_SIEGE_TOWN = "2 Rue Vincent Van Gogh, 93360 Neuilly-Plaisance"
export const SITE_INDIVIDUAL_TVA = "FR68912143237"
export const SITE_TELEPHONE = "01.84.80.86.55"
export const SITE_RCS_NUMBER = "912 143 237"
export const SITE_RCS_TOWN = "Bobigny"
export const SITE_CALL_IMAGES = "https://dt-website-prod.herokuapp.com/api/images/get-image"
// export const SITE_CALL_IMAGES = "http://locahost:8080/api/images/get-image"


export const MAX_ARTICLES_DISPLAYED = 12