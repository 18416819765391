// Cet object contient tout les chemins nécéssaire à l'application
// Lors de la création d'une nouvelle page pour l'application,
// il faut ajouter ici le chemin vers celle-ci associé à un nom (clé)

const PATH = {
  home: '',
  home_v2: 'home-v2',
  signup: 'inscription',
  login: 'connexion',
  legals: 'mentions-legales',
  contact: 'contact',
  change_pwd: 'changer-mot-de-passe',
  lost_pwd: 'mot-de-passe-oublie',
  reset_pwd: 'reset-password',
  about: 'l-agence',
  account: 'mon-compte',
  items: 'articles',
  services: 'services',
  conseils: 'societe-de-conseils',
  blog: 'blog',
  article: 'article',
  category: 'category',
  client: 'client',
  starterPack: 'starter-pack',
  starterPackForm: 'formulaire-starter-pack-form',
  page3D: 'page-3d',
  projects: "projets",
  jobs: 'jobs',
  
  //advices: 'conseils',
  packages: "forfait",
  recruitment: "recrutement",
  expertise: "expertise",
  privacy: "politique-de-confidentialite",
  gcu: "conditions-generales-d-utilisation",
  candidacy: "candidature",
  page404: "page-404",

  //Articles
  mvp: "quest-ce-quun-mvp",
  ecology: "comment-integrer-de-lecologie-dans-les-projets-digitaux",
  webapp: "progressive-web-app",
  api: "api-vs-microservices-comment-savoir-lequel-est-pour-moi",
  why: "quest-ce-que-le-why",
  client00: "ils-nous-font-confiance-1",

  //Jobs
  uxuiApprenticeship: "recrutement/alternant-ux-ui",
  frontApprenticeship: "recrutement/alternant-front-js",
  fullstackDev: "recrutement/developpeur-full-stack-js",
};

export default PATH;
