import React, { createContext, useCallback, useContext, useState } from "react";
import { EMPTY_CONTACT_MESSAGE, EMPTY_EMAIL, EMPTY_FIRSTNAME, EMPTY_LASTNAME, NOT_VALID_PHONE_NUMBER, EMPTY_CV } from "../../constants/cts_formErrors";
import { USER_MSG } from "../../constants/cts_contextErrors";
import { useTranslation } from "react-i18next";
import { checkEmptyInput, checkValidPhoneNumber } from "../../utils/checkInputs";
import { ICandidacyContext } from "../../interfaces/candidacies";
import { onSendCandidacyMessageApi } from "../../api/candidacy.api";

const CandidacyContext = createContext<ICandidacyContext | null>(null)

export const CandidacyProvider = (props : any) => {
  const { t } = useTranslation()
  const [ isLoading, _setIsLoading ] = useState(false)

  // send contact message
  const onSendCandidacyMessage = useCallback((
    {firstName, lastName, email, phoneNumber, presentation, cv}:
    {firstName: string, lastName: string, email: string, phoneNumber: string, presentation: string, cv: File}
  ) => {

    if (!checkEmptyInput(firstName)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_FIRSTNAME}`, {ns: "errors"}));
      });
    }
    if (!checkEmptyInput(lastName)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_LASTNAME}`, {ns: "errors"}));
      });
    }
    if (!checkEmptyInput(email)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_EMAIL}`, {ns: "errors"}));
      });
    }
    if(!checkValidPhoneNumber(phoneNumber)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${NOT_VALID_PHONE_NUMBER}`, {ns: "errors"}));
      });
    }
    if (!checkEmptyInput(presentation)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_CONTACT_MESSAGE}`, {ns: "errors"}));
      });
    }

    if (!cv) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_CV}`, {ns: "errors"}));
      });
    }

    _setIsLoading(true);

    return onSendCandidacyMessageApi({firstName, lastName, email, phoneNumber, presentation , cv})
            .then((response) => {
              _setIsLoading(false)
              return (response)
            })
            .catch((error) => {
              if (error.response) {
                throw new Error(error.response.data);
              } else {
                throw new Error(error.message)
              }
            })
            .then(() => _setIsLoading(false));
  }, [])

  return (
    <CandidacyContext.Provider 
      {...props}
      value={{
        isLoading,
        onSendCandidacyMessage
      }}
    />
  )
}

export const useCandidacy = (): ICandidacyContext => {
  const context = useContext(CandidacyContext)
  if (!context)
    throw new Error(USER_MSG);
  return context;
}
