import React, { useState, useCallback, createContext } from "react";
import { onGetItemApi, onGetAllItemsApi } from "../../api/item.api";
import { IItemsContext } from "../../interfaces/items";
import { useTranslation } from "react-i18next";

const ItemsContext = createContext(null);

export function ItemsProvider(props : any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation()

  const  onGetAllItems = useCallback(() => {
    _setIsLoading(true);
    return  onGetAllItemsApi()
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              });
  }, [])

  const onGetItem = useCallback((id : number) => {
    _setIsLoading(true);
    return  onGetItemApi(id)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  return (
    <ItemsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onGetAllItems,
        onGetItem,
      }}
    />
  );
}

export const useItems = () : IItemsContext => {
  const context = React.useContext(ItemsContext);
  if (!context)
    throw new Error(
      "useItems must be used in ItemProvider"
    );

  return context;
};
