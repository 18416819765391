export const scrollTo = (element: string) => {
  const domElement = document.querySelector(element);

  if (domElement) {
    const scrollTop = domElement.getBoundingClientRect().top + window.scrollY
    window.scrollTo({
      behavior: "smooth",
      top: scrollTop,
    });
  }
};

export function stringToSlug (string: string) {
  return string
          .toLowerCase()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // replace accents
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');
}
