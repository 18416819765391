import { instanceAxios } from "../utils/axios-api";

export const onGetAllJobsApi = () => {
  return instanceAxios
    .get(`/jobs/get-all-jobs`)
    .then((response) => (response.data ? response.data.data : null))
}

export const onGetJobApi = (slug : string) => {
  return instanceAxios
    .get(`/jobs/unique/${slug}`)
    .then((response) => (response.data ? response.data.data : null))
}
