import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { headerElements } from "../constants/cts_header";
import { useTranslation } from "react-i18next";
import { getPageUrl } from "../locales/i18n";
import {
  COLOR_BLACK,
  COLOR_GREY,
  COLOR_ORANGE_HOVER,
  COLOR_ORANGE_LIGHT,
  COLOR_TEXT,
  COLOR_WHITE,
} from "../constants/cts_colors";

const Header = ({ headerFixed = false }: { headerFixed?: boolean }) => {
  const [smallHeader, _setSmallHeader] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showMenu, _setShowMenu] = useState<boolean>(false);
  const [isSubItemActive, _setIsSubItemActive] = useState<boolean>(false);
  const [hoveredElementIndex, _setHoveredElementIndex] = useState<
    number | null
  >(null);
  const getCurrentDimension = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
  const [screenSize, _setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      _setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <Wrapper
      className={`${showMenu ? "white-bg" : ""} ${smallHeader ? "small" : ""}`}
      headerFixed={headerFixed}
    >
      <Navbar>
        <Top>
          <LogoTypo to={getPageUrl("/")}>DevTogether</LogoTypo>
        </Top>
      </Navbar>

      {/* toggle menu btn */}
      <ToggleMenuBtn
        className={`${showMenu ? "close-menu-btn" : "open-menu-btn"}`}
        onClick={() => _setShowMenu((lastVal) => !lastVal)}
      >
        <div className="toggle-menu-line line-1"></div>
        <div className="toggle-menu-line line-2"></div>
        <div className="toggle-menu-line line-3"></div>
      </ToggleMenuBtn>

      {/* menu */}
      <Menu className={showMenu ? "show" : ""}>
        <div className="overlay" />
        <div className="container">
          <ElementsContainer>
            {screenSize.width > 1024 &&
              headerElements.map((element, index) => (
                <video
                  key={"video-for-element-" + index}
                  className={index === hoveredElementIndex ? "show-video" : ""}
                  muted
                  autoPlay
                  loop
                >
                  {/*<source src={element.videoSrc ? element.videoSrc : ""} />*/}
                </video>
              ))}

            <div className="elements">
              {headerElements.map((element, index) => {
                return (
                  <div
                    key={"header-element-" + headerElements.indexOf(element)}
                  >
                    <NavLink
                      to={getPageUrl(element.to)}
                      onClick={() => _setShowMenu(false)}
                      className={({ isActive }) =>
                        (isActive || isSubItemActive ? "active" : "") +
                        " element"
                      }
                      onMouseEnter={() => _setHoveredElementIndex(index)}
                      onMouseLeave={() => _setHoveredElementIndex(null)}
                    >
                      {t(`elements.${element.name}`, {
                        ns: "header",
                      }).toUpperCase()}
                    </NavLink>
                    {element.subItems && (
                      <div className="sub-elements">
                        {element.subItems.map((item) => {
                          return (
                            <NavLink
                              to={getPageUrl(item.to)}
                              onClick={() => _setShowMenu(false)}
                              className={({ isActive }) =>
                                (isActive ? "active" : "") + " sub-element"
                              }
                              key={
                                "sub-item-" + element.subItems?.indexOf(item)
                              }
                            >
                              {t(`elements.${item.name}`, { ns: "header" })}
                            </NavLink>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </ElementsContainer>
        </div>
      </Menu>
    </Wrapper>
  );
};

export default Header;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

interface WrapperProps {
  headerFixed: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${(props) =>
    props.headerFixed
      ? ` 
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
`
      : ""};
`;

const LogoTypo = styled(Link)`
  font-size: 24px;
  font-family: "InterBold";
  line-height: 90%;
  color: ${COLOR_BLACK};
`;

const Navbar = styled.div`
  display: flex;
  padding-top: 40px;
  transition: background-color 0.6s ease-out;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0px 5%;
  justify-content: space-between;
`;

const ToggleMenuBtn = styled.div`
  position: fixed;
  top: 70px;
  right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  gap: 5px;
  z-index: 1002;

  .toggle-menu-line {
    height: 2px;
    background: ${COLOR_BLACK};
    transition: transform 0.6s ease-out, width 0.6s ease-out;
  }

  .line-1 {
    width: 100%;
  }

  .line-2 {
    width: 100%;
  }

  .line-3 {
    width: 100%;
  }

  &.close-menu-btn {
    .line-1 {
      transform: rotate(45deg) translate(5px, 5px);
    }

    .line-2 {
      width: 0%;
    }

    .line-3 {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }

  &.open-menu-btn {
    .line-1 {
      width: 100%;
    }

    .line-2 {
      width: 100%;
    }

    .line-3 {
      width: 100%;
    }
  }
`;

const Logo = styled.img`
  width: 123px;
  height: 80px;
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 1001;
  transition: visibility 0.5s ease-out;

  .container {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLOR_WHITE};
    transition: top 0.5s ease-out;
    padding: 15px;
  }

  .overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.5s ease-out, visibility 0.5s ease-out;
  }

  &.show {
    visibility: visible;

    .container {
      top: 0;
    }

    .overlay {
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
`;

const ElementsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${COLOR_WHITE};

  video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    visibility: hidden;
    animation: fadeOut 1s;

    &.show-video {
      visibility: visible;
      animation: fadeIn 1s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .elements {
    position: absolute;
    top: 0;
    left: 50%;
    width: 33.3%;
    height: 100%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR_WHITE};

    // ========= MEDIA QUERIES - ElementsContainer / .elements ============
    @media (max-width: 1024px) {
      width: 100%;
    }

    .element {
      color: ${COLOR_TEXT};
      font-size: 3rem;
      transition: color 0.2s ease-out;

      &:hover {
        color: ${COLOR_ORANGE_HOVER};
      }

      // ========= MEDIA QUERIES - ElementsContainer / .elements / .element ============
      @media (max-width: 1024px) {
        font-size: 2.5rem;
      }
    }

    .sub-elements {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: color 0.2s ease-out;

      .sub-element {
        font-size: 1.5rem;
        color: ${COLOR_GREY};

        // ========= MEDIA QUERIES - ElementsContainer / .elements / .element / .sub-elements / .sub-element ============
        @media (max-width: 1024px) {
          font-size: 1rem;
        }

        &:hover {
          color: ${COLOR_ORANGE_LIGHT};
        }
      }
    }
  }
`;
