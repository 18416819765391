import React, { createContext, useCallback, useContext, useState } from "react";
import { onGetClientBySlugApi, onGetAllClientsApi } from "../../api/client.api";
import { IClientsContext } from "../../interfaces/clients";

const ClientContext = createContext(null)

export const ClientProvider = (props : any) => {
  const [ isLoading, _setIsLoading ] = useState(false);

  // GET ALL CLIENTS
  const  onGetAllClients = useCallback(() => {
    _setIsLoading(true);
    return  onGetAllClientsApi()
              .then((response) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error) => {
                _setIsLoading(false)
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  // GET CLIENT BY SLUG
  const onGetClientBySlug = useCallback( async (slug: string) => {
    _setIsLoading(true);
    return  onGetClientBySlugApi(slug)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                _setIsLoading(false);
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  return (
    <ClientContext.Provider 
      {...props}
      value={{
        isLoading,
        // function
        onGetAllClients,
        onGetClientBySlug,
      }}
    /> 
  )
}

export const useClients = (): IClientsContext => {
  const context = useContext(ClientContext)
  if (!context)
    throw new Error('useClients must be used in ClientProvider');
  return context;
}
