import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import Providers from './common/contexts'
import "./locales/i18n"
import './assets/fonts/Cabinet_Grotesk/CabinetGrotesk-Bold.ttf'


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Fragment>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </Fragment>
);

// An array with the website links
// const links = [{ url: '/page-1/',  changefreq: 'daily', priority: 0.3  }]

// // Create a stream to write to
// const stream = new SitemapStream( { hostname: 'https://...' } )

// const sitemapGenerator = async () => {
//   // Return a promise that resolves with the XML string
//   return streamToPromise(Readable.from(links).pipe(stream)).then((data: any) =>
//     data.toString()
//   )
// }

// sitemapGenerator().then((result: string) => console.log(result));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
