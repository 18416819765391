import React, { useState, useCallback, createContext } from "react";
import { onGetJobApi, onGetAllJobsApi } from "../../api/jobs.api";
import { IJobsContext } from "../../interfaces/jobs";
import { useTranslation } from "react-i18next";

const JobsContext = createContext(null);

export function JobsProvider(props : any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation()

  const  onGetAllJobs = useCallback(() => {
    _setIsLoading(true);
    return  onGetAllJobsApi()
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              });
  }, [])

  const onGetJob = useCallback((slug : string) => {
    _setIsLoading(true);
    return  onGetJobApi(slug)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  return (
    <JobsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onGetAllJobs,
        onGetJob,
      }}
    />
  );
}

export const useJobs = () : IJobsContext => {
  const context = React.useContext(JobsContext);
  if (!context)
    throw new Error(
      "useJobs must be used in JobsProvider"
    );

  return context;
};
