import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet, useParams, useLocation } from "react-router-dom";
import { useAuthentification } from "../common/contexts/authentificationContext";
import WrapperConnected from "../components/wrapper-conected";
import PATH from "../constants/cts_routes";
import { I18nextProvider } from "react-i18next";
import i18n from "../locales/i18n";
import { DEFAULT_LANGUAGE } from "../constants/cts_language";
import axios from "axios";
import { useApp } from "../common/contexts/appContext";
import { languages } from "../constants/cts_languages";

const Page404 = React.lazy(() => import("./page404/page404"))
const SignUpPage = React.lazy(() => import("./signUp/signUp"));
const LoginPage = React.lazy(() => import("./login/login"));
const LostPassword = React.lazy(() => import("./lostPassword/lostPassword"));
const ResetPassword = React.lazy(() => import("./resetPassword/resetPassword"));
const LegalNotices = React.lazy(() => import("./legalNotices/legalNotices"));
const PDC = React.lazy(() => import("./privacy/privacy"));
const GCU = React.lazy(() => import("./gcu/gcu"));
const Contact = React.lazy(() => import("./contact/contact"));
const ChangePassword = React.lazy(() => import("./changePassword/changePassword"));
const Account = React.lazy(() => import("./account/account"));
const About = React.lazy(() => import("./about/about"));
const Items = React.lazy(() => import("./items/items"));
const Blog = React.lazy(() => import("./blog/blog"));
const Articles = React.lazy(() => import("./articles/articles"));
const ServicesPage = React.lazy(() => import("./services/services"));
const ClientPage = React.lazy(() => import("./clients/clientPage"));
const Conseils = React.lazy(() => import("./services/conseils"));
const Packages = React.lazy(() => import('./services/packages'));
const Candidacy = React.lazy(() => import("./candidacy/candidacy"));
const Recruitment = React.lazy(() => import("./recruitment/recruitment"));
const Job = React.lazy(() => import("./job/job"));
const Category = React.lazy(() => import("./category/category"));
const StarterPack = React.lazy(() => import("./services/starterPack"));
const HomePageTmp = React.lazy(() => import("./homePage/homePageTmp"));
const Page3D = React.lazy(() => import("./3d-page/3d-page"));
const HomePageV2 = React.lazy(() => import("./homePage/homePageV2"));
const ProjectsList = React.lazy(() => import("./projects/projectsList"))
const Project = React.lazy(() => import("./projects/project"))

// ⚠️ Lors de la création d'une nouvelle route, il faut ajouter le chemin dans le
// fichier "front/src/constants/cts_routes.tsx"

const AppRoutes = () => {
  const [stripeClientSecret, _setStripeClientSecret] = useState<string>('')
  const { user } = useAuthentification()

  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={
          <RedirectLanguagePage />
        } />
        <Route path="/:lang" element={<LanguagePage />}>
          <Route
            index
            element={
              <Suspense>
                <WrapperConnected headerFixed={true}>
                  <HomePageV2 />
                </WrapperConnected>
              </Suspense>
            }
          />
          {/*<Route
            path={PATH.home_v2}
            element={
              <WrapperConnected headerFixed={true}>
                <HomePageV2 />
              </WrapperConnected>
            } />*/}
          <Route
            path={PATH.signup}
            element={
              <WrapperConnected>
                <SignUpPage />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.login}
            element={
              <WrapperConnected>
                <LoginPage />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.lost_pwd}
            element={
              <WrapperConnected>
                <LostPassword />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.reset_pwd}
            element={
              <WrapperConnected>
                <ResetPassword />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.legals}
            element={
              <Suspense>
                <WrapperConnected>
                  <LegalNotices />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.privacy}
            element={
              <Suspense>
                <WrapperConnected>
                  <PDC />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.gcu}
            element={
              <Suspense>
                <WrapperConnected>
                  <GCU />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.page3D}
            element={
              <WrapperConnected>
                <Page3D />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.blog}
            element={
              <Suspense>
                <WrapperConnected>
                  <Blog />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.blog + "/page/:id"}
            element={
              <Suspense>
                <WrapperConnected>
                  <Blog />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.category + "/:slug"}
            element={
              <Suspense>
                <WrapperConnected>
                  <Category />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.article + "/:slug"}
            element={
              <Suspense>
                <WrapperConnected>
                  <Articles />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.client + "/:slug"}
            element={
              <Suspense>
                <WrapperConnected>
                  <ClientPage />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.services}
            element={
              <Suspense>
                <WrapperConnected>
                  <ServicesPage />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.starterPack}
            element={
              <Suspense>
                <WrapperConnected>
                  <StarterPack />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.packages}
            element={
              <Suspense>
                <WrapperConnected>
                  <Packages />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.conseils}
            element={
              <Suspense>
                <WrapperConnected>
                  <Conseils />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.contact}
            element={
              <Suspense>
                <WrapperConnected>
                  <Contact />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.candidacy}
            element={
              <Suspense>
                <WrapperConnected>
                  <Candidacy />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.recruitment}
            element={
              <Suspense>
                <WrapperConnected>
                  <Recruitment />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.recruitment + '/:slug'}
            element={
              <Suspense>
                <WrapperConnected>
                  <Job />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route
            path={PATH.about}
            element={
              <Suspense>
                <WrapperConnected>
                  <About />
                </WrapperConnected>
              </Suspense>

            }
          />
          <Route 
            path={PATH.projects}
            element={
              <Suspense>
                <WrapperConnected>
                  <ProjectsList />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route 
            path={PATH.projects + '/:id' + '/:slug'}
            element={
              <Suspense>
                <WrapperConnected>
                  <Project />
                </WrapperConnected>
              </Suspense>
            }
          />
          <Route element={<ProtectedRoute user={user} />}>
            <Route
              path={PATH.items}
              element={ 
                <WrapperConnected >
                  <Items stripeClientSecret={stripeClientSecret} _setStripeClientSecret={_setStripeClientSecret} />
                </WrapperConnected>
              }
            />
            <Route
              path={PATH.change_pwd}
              element={
                <WrapperConnected>
                  <ChangePassword />
                </WrapperConnected>
              }
            />
            <Route
              path={PATH.account}
              element={
                <WrapperConnected>
                  <Account />
                </WrapperConnected>
              }
            />
          </Route>
          <Route
            path={PATH.page404}
            element={
              <Suspense>
                <WrapperConnected>
                  <Page404 />
                </WrapperConnected>
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Suspense>
              <WrapperConnected>
                <Page404 />
              </WrapperConnected>
            </Suspense>
          }
        />
      </Routes>
    </I18nextProvider>
  );
};

const ProtectedRoute = ({ user, path = "/" }: { user: any, path?: string }) => {
  const { isWaitingAutoLogin } = useAuthentification()
  if (!isWaitingAutoLogin) {
    if (!user)
      return <Navigate to={path} replace />
    return <Outlet />
  } else if (!localStorage.getItem("token") && !axios.defaults.headers.common["Authorization"]) {
    return <Navigate to={path} replace />;
  }
  return null;
}

const LanguagePage: React.FC = (): JSX.Element => {
  const { lang } = useParams();
  const location = useLocation();
    const { onSendLanguage } = useApp();

  useEffect(() => {

    // send selected language to the server
    // the language will be changed on api
    onSendLanguage(lang || DEFAULT_LANGUAGE)
      // change language in front
      .then(() => i18n.changeLanguage(lang));
  }, [lang]);

  if(!languages.find(l => l.code === lang)) {
    const urlRedirected = location.pathname.replace('/'+lang, '')
    return <Navigate to={`/${DEFAULT_LANGUAGE}${urlRedirected}`} />;
  }

  return <Outlet />;
};

class RedirectLanguagePage extends React.Component {
  render() {
    let navigatorLangageDetected = /*navigator.language
      ? navigator.language
      : */DEFAULT_LANGUAGE;
      // TODO FORCE FR

    // remove - to language string
    navigatorLangageDetected = navigatorLangageDetected.split("-")[0];

    return <Navigate to={`/${navigatorLangageDetected}`} />;
  }
}

export default AppRoutes;
