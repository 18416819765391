import React, { useState, createContext, useContext, useCallback } from "react";
import { IProjectsContext } from "../../interfaces/projects";
import {  onGetAllPublishedProjectsApi, onGetProjectApi } from "../../api/projects.api";
import { useTranslation } from "react-i18next";

const ProjectsContext = createContext(null);

export const ProjectsProvider = (props : any) => {
  const { t } = useTranslation()
  const [isLoading, _setIsLoading] = useState(false);

  const  onGetAllPublishedProjects = useCallback(async () => {
    _setIsLoading(true);
    return  onGetAllPublishedProjectsApi()
              .then((response : any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])

  const onGetProject = useCallback(async (id : number) => {
    _setIsLoading(true);
    return  onGetProjectApi(id)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message);
                }
              })
  }, [])


  return (
    <ProjectsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onGetAllPublishedProjects,
        onGetProject,
      }}
    />
  );
}

export const useProjects = () : IProjectsContext => {
  const context = useContext(ProjectsContext);
  if (!context)
    throw new Error(
      "useProjects must be used in ProjectProvider"
    );
  return context;
};
