// Le tableau d'objet ci dessous permet de lister les element à mettre dans le footer
// de l'application. Lors de la création d'un nouvel élement, il faut indiquer le nom
// ainsi que le chemin associé
// ⚠️⚠️ Lors de la création d'un nouvel item, la route correspondante doit être ajouté
// au fichier "front/src/constants/cts_routes.tsx"

import { IMenuFooterElements } from "../interfaces/menu"
import PATH from "./cts_routes";

// the names are used in translation
export const footerElements : IMenuFooterElements[] = [
  {
    name: 'agency',
    to: PATH.about,
  },
  {
    name: 'expertise',
    to: PATH.packages,
  },
  {
    name: 'recruitment',
    to: PATH.recruitment,
  },
  {
    name: 'blog',
    to: PATH.blog,
  },
  {
    name: 'contact',
    to: PATH.contact,
  },
  {
    name : "legals",
    to : PATH.legals,
  },
  {
    name : "privacy",
    to : PATH.privacy,
  },
  {
    name : "gcu",
    to : PATH.gcu,
  },
  {
    name : "candidacy",
    to : PATH.candidacy,
  },
  // {
  //   name : "starterPack",
  //   to : PATH.starterPack,
  // },
  // {
  //   name : "starterPackForm",
  //   to : PATH.starterPackForm,
  // },
  {
    name : "home",
    to : PATH.home
  },
  
];

export const footerArticles : IMenuFooterElements[] = [
  {
    name : "mvp",
    to : PATH.mvp,
  },
  {
    name : "ecology",
    to: PATH.ecology
  },
  {
    name: "webapp",
    to: PATH.webapp,
  },
  {
    name: "api",
    to: PATH.api,
  },
  {
    name: "why",
    to: PATH.why,
  },
  {
    name: "client00",
    to: PATH.client00,
  }
]

export const footerJobs : IMenuFooterElements[] = [
  {
    name: "uxuiApprenticeship",
    to: PATH.uxuiApprenticeship,
  },
  {
    name: "frontApprenticeship",
    to: PATH.frontApprenticeship,
  },
  {
    name: "fullstackDev",
    to: PATH.fullstackDev,
  },
]