import React, { useState, useCallback, createContext } from 'react'
import { onGetImageApi } from '../../api/image.api';
import { IImagesContext } from '../../interfaces/images';

const ImagesContext = createContext(null)

export function ImagesProvider(props: any){

  const [isLoading, _setIsLoading] = useState(false);

  //get image
  const onGetImage = useCallback((imageURL : string, width: number, height: number, slug: string) => {
    _setIsLoading(true);
    return onGetImageApi(imageURL, width, height, slug)
              .then((response: any) => {
                _setIsLoading(false)
                return (response)
              })
              .catch((error: any) => {
                _setIsLoading(false)
                if(error.response) {
                  throw new Error(error.message.data);
                } else {
                  throw new Error(error.message.data);
                }
              })
  }, [])

  return (
    <ImagesContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onGetImage,
      }}
    />
  );
}

export const useImages = () : IImagesContext => {
  const context = React.useContext(ImagesContext);
  if (!context)
    throw new Error(
      "useImages must be used in ImageProvider"
    );

  return context;
};
