// Le tableau d'objet ci dessous permet de lister les element à mettre dans le header
// de l'application. Lors de la création d'un nouvel élement, il faut indiquer le nom,
// aisin que le chemin associé
// ⚠️⚠️ Lors de la création d'un nouvel item, la route correspondante doit être ajouté
// au fichier "front/src/constants/cts_routes.tsx"

import { IMenuElements } from "../interfaces/menu";
import PATH from "./cts_routes";

// the names are used in translation
export const headerElements : IMenuElements[] = [ 
  {
    name : "home",
    to : PATH.home,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019801792homepage-slideshow-2.mp4",
  },
  {
    name : "about",
    to : PATH.about,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019835788expertises-card-1.mp4",
  },
  {
    name : "projects",
    to : PATH.projects,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019801792homepage-slideshow-2.mp4",
  },
  {
    name: "services",
    to: PATH.services,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019801792homepage-slideshow-2.mp4",
    subItems: [
      // {
      //   name: 'starterPack',
      //   to: PATH.starterPack,
      // },
      {
        name: 'advices',
        to: PATH.conseils,
      },
      {
        name: 'packages',
        to: PATH.packages,
      }
    ],
  },
  {
    name: "blog",
    to: PATH.blog,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019801792homepage-slideshow-2.mp4",
  },
  {
    name : "recruitment",
    to : PATH.recruitment,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019835788expertises-card-1.mp4",
  },
  {
    name : "contact",
    to : PATH.contact,
    videoSrc : "https://1693712952.rsc.cdn77.org/99132677/backup/1685019801792homepage-slideshow-2.mp4",
  },
];